import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  public _totalPages: number;
  @Input() set totalPages(value: number) {
    this._totalPages = value;
    this.getDisplayedPages();
  }
  public _currentPage: number;
  @Input() set currentPage(value: number) {
    this._currentPage = value;
    this.getDisplayedPages();
  }
  public _selectedPageSize: number;
  @Input() set selectedPageSize(value: number) {
    this._selectedPageSize = value;
    this.getDisplayedPages();
  }
  public pageSizes: Array<number> = [5, 10, 20, 30, 50];
  public pages: number[] = [];

  @Output() onChangeCurrentPage: EventEmitter<number> = new EventEmitter();
  @Output() onSelectPageSize: EventEmitter<number> = new EventEmitter();

  ngOnInit(): void {
    this.getDisplayedPages();
  }

  public goFirstPage(): void {
    this.getDisplayedPages();
    this._currentPage = 0;
    this.onChangeCurrentPage.emit(this._currentPage);
  }

  public goLastPage(): void {
    this.getDisplayedPages();
    this._currentPage = this._totalPages - 1;
    this.onChangeCurrentPage.emit(this._currentPage);
  }

  public goPrevPage(): void {
    this._currentPage--;
    this.changePage();
  }

  public goToPage(page: number): void {
    this._currentPage = page;
    this.changePage();
  }

  public goNextPage(): void {
    this._currentPage++;
    this.changePage();
  }

  public prevDisabled(): boolean {
    return this._currentPage === 0 || this._totalPages === 0;
  }

  public nextDisabled(): boolean {
    return this._currentPage === this._totalPages - 1 || this._totalPages === 0;
  }

  public selectPageSize(size: number) {
    this._selectedPageSize = size;
    this.onSelectPageSize.emit(this._selectedPageSize);
  }

  private changePage(): void {
    this.getDisplayedPages();
    this.onChangeCurrentPage.emit(this._currentPage);
  }

  private getDisplayedPages(): void {
    this.pages = [];
    if(this._currentPage === 0) {
      let i: number = 0;
      while(this.pages.length < 3) {
        this.pages.push(i);
        i++;
        if(i === this._totalPages) return;
      }
      return;
    }
    if(this._currentPage === this._totalPages - 1) {
      let i: number = this._totalPages - 1;
      while(this.pages.length < 3) {
        this.pages.push(i);
        i--;
        if(i === -1) {
          this.pages = this.pages.reverse();
          return;
        }
      }
      this.pages = this.pages.reverse();
      return;
    }

    let i: number = this._currentPage - 1;
    while(this.pages.length < 3) {
      this.pages.push(i);
      i++;
    }
  }
}