import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'recordName' })
export class RecordNamePipe implements PipeTransform {

  transform(name: string): string {
    let result: string;
    if(name.includes(' ')) {
      let splitted = name.split(' ');
      result = splitted[0].charAt(0).toUpperCase() + splitted[1].charAt(0).toUpperCase()
    } else {
      result = name.charAt(0).toUpperCase() + name.charAt(1).toUpperCase()
    }
    return result;
  }
}
