import { PRESENCE_STATUS } from "../sip";

export enum ADDRESSBOOK_TYPES {
  INTERNAL_USER = 'internal-user',
  INTERNAL_GROUP = 'internal-group',
  EXTERNAL = 'external',
  CRM = 'crm'
};

export enum GROUP_TYPES {
  GROUP = 'GROUP',
  CASCADE = 'CASCADE',
  QUEUE = 'QUEUE'
}

export interface InternalUserResponse {
  user_id: string;
  email: string;
  firstName: string;
  lastName: string;
  extension: string;
  phoneNumber?: string;
  note?: string;
  presence_id?: string;
}

export interface InternalGroupResponse {
  group_id: string;
  name: string;
  description?: string;
  extension: string;
  group_type: GROUP_TYPES
}

export interface VCardTel {
  label: string | undefined;
  number: string;
  type?: string;
  capability?: string[];
  group?: string;
}

export interface VCardImportStatistics {
  total: number;
  skipped: number;
  added: number;
  errored: number;
}

// Can be used to separate the type of number (i.e. work, home) from it's capability
export const TEL_CAPABILITIES = ['text', 'voice', 'fax', 'cell', 'video', 'pager', 'textphone'];

export interface CrmLookupResponse {
  "Contact Name": string;
  Title: string;
  Number: CrmLookupNumbers[];
  Email: CrmLookupEmails[];
  "Crm Link": string;
  Id: string;
  CRM: string;
  "Company Name": string;
  CRM_Icon: string;
  phonesList?: CrmLookupPhonelist[];
}

export interface CrmLookupNumbers {
  original: string;
  stripped: string;
  national: string;
  E164: string;
  national_stripped: string;
}

export interface CrmLookupEmails {
  label: string;
  value: string;
}

export interface CrmLookupPhonelist {
  label: string;
  value: string;
}

export interface CrmLookupFilters {
  phone?: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  company?: string;
}

export interface CrmLookupPhoneFilter {
  phone: string;
}

export interface AddressbookResponse {
  groups: InternalGroupResponse[];
  users: InternalUserResponse[];
}

export interface AddressbookRecord {
  id?: number;
  remoteId?: string;
  type: ADDRESSBOOK_TYPES;
  firstName: string;
  lastName?: string;
  defaultNumber: AddressbookRecordNumber;
  numbers: AddressbookRecordNumber[];
  hash?: string;
  link?: string;
  avatar?: string;
  groupType?: GROUP_TYPES;
  indexableTelNumbers?: string[];
  icon?: string;
  CRM?: string;
  favorite?: number;
  presenceId?: string;
  status?: PRESENCE_STATUS;
  emails?: {
    label?: string;
    email: string;
  }[];
  notes?: string;
}

export interface RecordsToDisplayModel {
  all: AddressbookRecord[];
  filtered: AddressbookRecord[];
  displayed: AddressbookRecord[];
}

export interface AddressbookRecordNumber {
  label?: string;
  number: string;
}