import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SettingsFacade } from '../../store/facade';
import { AudioConfigModel } from '../../store/states-models';
import { ConfigService } from '../config';


@Injectable({ providedIn: 'root' })
export class DeviceManagerService {

  private audioDevices: MediaDeviceInfo[] = []; // This is needed to search things.
  public hasPermission: boolean = false;
  public audioInputDevices: MediaDeviceInfo[] = []; // This is needed to search things.
  public audioOutputDevices: MediaDeviceInfo[] = []; // This is needed to search things.
  public micLevel$: BehaviorSubject<number> = new BehaviorSubject(0);

  // audio analyzer things
  private audioCtx: AudioContext;
  private analyser: AnalyserNode;
  private mediaStreamAudioSourceNode: MediaStreamAudioSourceNode;
  private scriptProcessorNode: ScriptProcessorNode;
  

  constructor(
    private settingsFacade: SettingsFacade
    ) {
    this.enumerateAudioDevices();
    navigator.mediaDevices.ondevicechange = this.enumerateAudioDevices;
  }

  public askForPermission() {
    navigator.permissions.query(<any>{
      name: 'microphone'
    }).then((status: PermissionStatus) => {
      if(status.state === 'prompt'){
        navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false
        }).then((stream) => {
          stream.getAudioTracks().forEach(track => track.stop());
          this.hasPermission = true;
        }).catch(
          (err) => {
            if(err === 'PERMISSION_DENIED') {
              this.hasPermission = false;
            }
          }
        );
      } else if(status.state === 'granted') {
        this.hasPermission = true;
      } else {
        this.hasPermission = false;
      }
    });
  }

  /**
   * Enumerate all devices presents
   */
  public enumerateAudioDevices = async () => {
    // TODO: we use getUserMedia because it waits for the user to accept or not the permissions
    await navigator.mediaDevices.getUserMedia({audio: true, video: false});
    const devices = (await navigator.mediaDevices.enumerateDevices()).filter((d: MediaDeviceInfo) =>
      ['audioinput', 'audiooutput'].includes(d.kind));
    this.audioDevices = devices;
    this.audioInputDevices = devices.filter(el => el.kind === 'audioinput');
    this.audioOutputDevices = devices.filter(el => el.kind === 'audiooutput');
    this.settingsFacade.setAudioDeviceEnumeration(devices);
    return devices;
  }

  /**
   * Get media info by id
   * @param {string} deviceId Id of the devices to find
   */
  private getInputMediaInfoById(deviceId: string) {
    const device = this.audioInputDevices.find(e => e.deviceId === deviceId);
    return device ? device : this.getDefaultMediaInfo(this.audioInputDevices);
  }

  /**
   * Get media info by id
   * @param {string} deviceId Id of the devices to find
   */
  private getOutputMediaInfoById(deviceId: string) {
    const device = this.audioOutputDevices.find(e => e.deviceId === deviceId);
    return device ? device : this.getDefaultMediaInfo(this.audioOutputDevices);
  }

  private getDefaultMediaInfo(audioList: MediaDeviceInfo[]): MediaDeviceInfo {
    return audioList.find(e => e.deviceId.includes('default'));
  }

  /**
   * Set audio devices by reading the audio section of the config
   * @param {} audio Audio section of the config file
   */
  public setConfigDevices(audio: AudioConfigModel) {
    // Get the lists of device and check if the ones from config are present.
    this.settingsFacade.setAudioInputDevice(this.getInputMediaInfoById((<MediaDeviceInfo>audio?.audio_input_device)?.deviceId));
    this.settingsFacade.setAudioOutputDevice(this.getOutputMediaInfoById((<MediaDeviceInfo>audio?.audio_output_device)?.deviceId));
    this.settingsFacade.setAudioRingDevice(this.getOutputMediaInfoById((<MediaDeviceInfo>audio?.audio_ring_device)?.deviceId));
  }

  /**
   * Set the audio input device
   * @param {string} device Input device to save
   */
  public setAudioInputDevice(device: string) {
    this.settingsFacade.setAudioInputDevice(this.audioInputDevices.find(el => el.label === JSON.parse(device).label));
  }

  /**
   * Set the audio output device
   * @param {string} device Output device to save
   */
  public setAudioOutputDevice(device: string) {
    this.settingsFacade.setAudioOutputDevice(this.audioOutputDevices.find(el => el.label === JSON.parse(device).label));
  }

  /**
   * Set the ringing device
   * @param {string} device Ring device to save
   */
  public setAudioRingDevice(device: string) {
    this.settingsFacade.setAudioRingDevice(this.audioOutputDevices.find(el => el.label === JSON.parse(device).label));
  }

  /**
   * Attach analyzer to stream
   * @param {MediaStream} stream Current stream
   */
  public attachAnalyserToStream(stream: MediaStream): void {
    this.disconnectAllAudioContextNode();
    this.audioCtx = new AudioContext();
    this.analyser = this.audioCtx.createAnalyser();
    this.mediaStreamAudioSourceNode = this.audioCtx.createMediaStreamSource(stream);
    this.scriptProcessorNode = this.audioCtx.createScriptProcessor(2048, 1, 1);
    this.analyser.smoothingTimeConstant = 0.8;
    this.analyser.fftSize = 1024;

    this.mediaStreamAudioSourceNode.connect(this.analyser);
    this.analyser.connect(this.scriptProcessorNode);
    this.scriptProcessorNode.connect(this.audioCtx.destination);
    this.scriptProcessorNode.onaudioprocess = (() => {
      const array = new Uint8Array(this.analyser.frequencyBinCount);
      this.analyser.getByteFrequencyData(array);
      const arraySum = array.reduce((a, value) => a + value, 0);
      const average = arraySum / array.length;
      this.micLevel$.next(average);
    });
  }

  public disconnectAllAudioContextNode() {
    this.analyser?.disconnect();
    this.mediaStreamAudioSourceNode?.disconnect();
    this.scriptProcessorNode?.disconnect();
  }
}