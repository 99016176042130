import { SettingsState } from "../../states-models";
import {
  SettingsAction,
  changeLanguage,
  changeAudioDeviceEnumeration,
  changeAudioInputDevice,
  changeAudioOutputDevice,
  changeAudioRingDevice,
  changeIntercomChatOpen,
  changeDoNotDisturb,
  changeCallWaiting,
  changeUpdateDownloaded,
} from "../../actions";

/**
 * Initial state for the Settings part of the store
 */
export const initialState: SettingsState = {
  language: "",
  doNotDisturb: false,
  callWaiting: true,
  audio: {
    audio_devices_enum: null,
    audio_input_device: null,
    audio_output_device: null,
    audio_ring_device: null
  },
  intercom_chat_open: false,
  updateDownloaded: false
};

/**
 * The reducer of the settings part of the store
 * @param state Current state of the settings part of the store
 * @param action Current action to manage.
 */
export function settingsReducer(
  state = initialState,
  action: SettingsAction
): SettingsState {
  switch (action.type) {
    case changeLanguage.type: {
      return {
        ...state,
        language: action.language
      };
    }
    case changeAudioDeviceEnumeration.type: {
      return {
        ...state,
        audio: {
          ...state.audio,
          audio_devices_enum: action.enumeration
        }
      };
    }
    case changeAudioInputDevice.type: {
      return {
        ...state,
        audio: {
          ...state.audio,
          audio_input_device: action.inputDevice
        }
      };
    }
    case changeAudioOutputDevice.type: {
      return {
        ...state,
        audio: {
          ...state.audio,
          audio_output_device: action.outputDevice
        }
      };
    }
    case changeAudioRingDevice.type: {
      return {
        ...state,
        audio: {
          ...state.audio,
          audio_ring_device: action.ringDevice
        }
      };
    }
    case changeIntercomChatOpen.type: {
      return {
        ...state,
        intercom_chat_open: action.intercom_chat_open
      };
    }
    case changeDoNotDisturb.type: {
      return {
        ...state,
        doNotDisturb: action.dnd
      }
    }
    case changeCallWaiting.type: {
      return {
        ...state,
        callWaiting: action.callWaiting
      };
    }
    case changeUpdateDownloaded.type: {
      return {
        ...state,
        updateDownloaded: action.updateDownloaded
      }
    }
    default: {
      return state;
    }
  }
}
